<script lang="ts">
    import { defineComponent } from 'vue';
    

    export default defineComponent({
        components: {},
        data() {
            return {
                windowWidth: window.innerWidth,
                campuses: [
                    {
                        image: '../pagesImages/home-abeja-2025.webp',
                        image2: '/pagesImages/home-abeja-version-movil.webp',
                        type: 'Sé tu mejor versión.',
                        name: '',
                        address: '',
                        visitLink: '',
                    },
                    {
                        image: '../pagesImages/Campus_Cancun.webp',
                        image2: '../pagesImages/Campus_Cancun.webp',
                        type: 'Campus Magno ',
                        name: 'Cancún',
                        address: 'Cancún, Quintana Roo',
                        visitLink: 'https://humanitas.edu.mx/campus/cancun',
                    },
                    {
                        image: '../pagesImages/Campus_Valle.webp',
                        image2: '../pagesImages/Campus_Valle.webp',
                        type: 'Campus Magno ',
                        name: 'Del Valle',
                        address: 'Ciudad de México',
                        visitLink: 'https://humanitas.edu.mx/campus/del-valle',
                    },
                    {
                        image: '../pagesImages/Campus_Reyes.webp',
                        image2: '../pagesImages/Campus_Reyes.webp',
                        type: 'Campus Magno ',
                        name: 'Los Reyes',
                        address: 'Estado de México',
                        visitLink: 'https://humanitas.edu.mx/campus/los-reyes',
                    },
                    {
                        image: '../pagesImages/Campus_Merida.webp',
                        image2: '../pagesImages/Campus_Merida.webp',
                        type: 'Campus Magno ',
                        name: 'Mérida',
                        address: 'Mérida, Yucatán',
                        visitLink: 'https://humanitas.edu.mx/campus/merida',
                    },
                    {
                        image: '../pagesImages/Campus_Madin.webp',
                        image2: '../pagesImages/Campus_Madin.webp',
                        type: 'Campus Magno ',
                        name: 'Presa Madín',
                        address: 'Estado de México',
                        visitLink: 'https://humanitas.edu.mx/campus/presa-madin',
                    },
                    {
                        image: '../pagesImages/Campus_Queretaro.webp',
                        image2: '../pagesImages/Campus_Queretaro.webp',
                        type: 'Campus Magno ',
                        name: 'Querétaro',
                        address: 'Juriqilla, Querétaro',
                        visitLink: 'https://humanitas.edu.mx/campus/queretaro',
                    },
                    {
                        image: '../pagesImages/Campus_Tijuana.webp',
                        image2: '../pagesImages/Campus_Tijuana.webp',
                        type: 'Campus Magno ',
                        name: 'Tijuana',
                        address: 'Tijuana, BC.',
                        visitLink: 'https://humanitas.edu.mx/campus/tijuana',
                    },
                    {
                        image: '../pagesImages/Campus_Chihuahua.webp',
                        image2: '../pagesImages/Campus_Chihuahua.webp',
                        type: 'Campus Magno ',
                        name: 'Chihuahua',
                        address: 'Chihuahua, Chihuahua',
                        visitLink: 'https://humanitas.edu.mx/campus/chihuahua',
                    },
                    {
                        image: '../pagesImages/Campus_SantaFe.webp',
                        image2: '../pagesImages/Campus_SantaFe.webp',
                        type: 'Campus Ejecutivo ',
                        name: 'Santa Fe',
                        address: 'Ciudad de México',
                        visitLink: 'https://humanitas.edu.mx/campus/santa-fe',
                    },
                    {
                        image: '../pagesImages/Campus_Guadalajara.webp',
                        image2: '../pagesImages/Campus_Guadalajara.webp',
                        type: 'Campus Ejecutivo ',
                        name: 'Guadalajara',
                        address: 'Guadalajara, Jalisco',
                        visitLink: 'https://humanitas.edu.mx/campus/guadalajara',
                    },
                    {
                        image: '../pagesImages/Campus_Monterrey.webp',
                        image2: '../pagesImages/Campus_Monterrey.webp',
                        type: 'Campus Ejecutivo ',
                        name: 'Monterrey',
                        address: 'Próximamente',
                        visitLink: '',
                    },
                    {
                        image: '../pagesImages/home-campus-virtual.webp',
                        image2: '../pagesImages/home-virtual-movil.webp',
                        type: 'Campus ',
                        name: 'Virtual',
                        address: 'Ciudad de México',
                        visitLink: 'https://virtual.humanitas.edu.mx/',
                    },
                    {
                        image: '../pagesImages/home-campus-virtual-colombia.webp',
                        image2: '../pagesImages/home-virtual-colombia-redo.webp',
                        type: 'Campus Virtual ',
                        name: 'Colombia',
                        address: 'Bogotá, Col.',
                        visitLink: 'https://virtual.humanitas.edu.mx/colombia/',
                    },
                ],
                currentCampus: 0,
                isDesktop: window.innerWidth >= 768,
                currentImage: '',
                timer: null,
            };
        },
        computed: {
            currentCampusData() {
                return this.campuses[this.currentCampus];
            },
            showCampus() {
                return this.currentCampus !== 0;
            },
        },
        methods: {
            // Precargar una imagen
            preloadImage(src: string): void {
                const img = new Image();
                img.src = src;
            },
            preloadImages(): void {
                const firstCampus = this.campuses[0];
                this.currentImage = this.isDesktop ? firstCampus.image : firstCampus.image2;
                // Precargar la imagen seleccionada
                this.preloadImage(this.currentImage);
            },
            changeCampus(index: number): void {
                this.currentCampus = index;
                this.resetTimer();
            },
            resetTimer(): void {
                //@ts-ignore
                clearInterval(this.timer);
                this.startTimer();
            },
            startTimer(): void {
                // @ts-ignore
                this.timer = setInterval(() => {
                    const nextIndex = (this.currentCampus + 1) % this.campuses.length;
                    this.changeCampus(nextIndex);
                }, 5000); // Cambia el intervalo según sea necesario (en milisegundos)
            },
        },
        mounted() {
            this.startTimer();
            this.preloadImages();
        },
        beforeUnmount() {
            //@ts-ignore
            clearInterval(this.timer);
        },
    });
</script>

<template>
    <div fetchpriority="high" class="relative mb-12 carousel-container max-h-screen min-h-[642px] h-full">
        <transition name="fade">
            <div :key="currentCampusData.image" class="active flex  flex-col items-center justify-center">
                <img :src="currentCampusData.image2" :alt="currentCampusData.type + ' ' + currentCampusData.name" class="object-cover md:hidden object-center h-screen w-screen pt-[80px] lg:pt-[138px]" loading="eager" fetchpriority="high" width="1920" height="1080"/>
                <img :src="currentCampusData.image" :alt="currentCampusData.type + ' ' + currentCampusData.name" class="object-cover hidden object-center md:block h-screen w-screen pt-[88px] lg:pt-[140px]" fetchpriority="high" width="640" height="568" loading="eager" />
                <div v-if="currentCampusData.type !== ''" class="absolute  text-center pt-14 md:text-left text-white z-10 w-full max-w-4xl lg:max-w-6xl flex flex-col lg:justify-center justify-start items-center lg:items-start drop-shadow-md">
                    <h2 v-if="currentCampus != 0" 
                        class="lg:text-3xl text-xl uppercase whitespace-nowrap bg-gray-500 bg-opacity-5 mb-3 "
                        style="letter-spacing: 10px; text-shadow: 1px 1px 0px black, -1px 1px 0px black, 1px -1px 0px black, -1px -1px 0px black, 0 4px 6px rgba(0, 0, 0, 1);">
                        <span class="block lg:text-3xl text-xl whitespace-nowrap">{{ currentCampusData.type }}</span>
                        <span class="sr-only whitespace-nowrap">&nbsp;</span>
                        <span class="block lg:text-5xl text-3xl whitespace-nowrap">{{ currentCampusData.name }}</span>
                    </h2>
                    <a :href="currentCampusData.visitLink" v-if="currentCampusData.visitLink !== ''" target="_blank">
                        <button class="flex items-center focus:outline-none justify-center bg-white/90 rounded-none hover:bg-white/60 lg:text-[24px] text-[#881912] border-[1.5px] border-[#881912] w-[140px] h-[35px] lg:w-[210px] lg:h-[50px] transition duration-300 ease-in-out">
                            <span>Vis&iacute;tanos</span>
                        </button>
                    </a>
                    <p class="lg:text-2xl text-[18px] lg:h-[50px] h-[35px] uppercase" style="letter-spacing: 10px; text-shadow: 1px 1px 0px black, -1px 1px 0px black,1px -1px 0px black, -1px -1px 0px black, 0 4px 6px rgba(0, 0, 0, 1); 
                    " v-else>{{currentCampusData.address}}</p>
                </div>
            </div>
        </transition>
        <div class="flex relative -top-12 justify-center mt-4 md:space-x-3 space-x-2">
            <span
                v-for="(_dot, index) in campuses"
                :key="index"
                :class="{ 'bg-gray-400': index !== currentCampus, 'bg-[#881912]': index === currentCampus }"
                class="h-2 w-2 rounded-full cursor-pointer"
                @click="changeCampus(index)"
            ></span>
        </div>
    </div>
</template>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s ease-in-out;
        }
        .fade-enter, .fade-leave-to {
        opacity: 0;
        position: absolute;
        }
        .carousel-container {
            position: relative;
            overflow: hidden;
        }
        .carousel-container img {
            object-fit: cover; /* Mantiene la imagen cubierta sin desbordar */
    }
</style>


