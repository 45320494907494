<script lang="ts">
	import { RouterLink } from 'vue-router';
	import { defineComponent } from 'vue';
	import Botones from '@/components/Botones.vue';


	export default defineComponent({
		components: {
			RouterLink, Botones
		},
		data() {
			return {
				buttons: {
                    button1: {
						imageUrl: "../pagesImages/acceso.jpg",
						hoverImageUrl: "../pagesImages/acceso_on.jpg"
					}
                },
				isMenuOpen: false,
			}
		},
		methods: {
            toggleMenu() {
				this.isMenuOpen = !this.isMenuOpen;
            },
        },
	});
</script>

<template>
	<nav class="bg-white max-w-4xl lg:max-w-[1200px] z-[48]">
		<div class="fixed top-0 left-0 w-full bg-white flex flex-col items-center min-h-[86px] justify-center shadow-md z-[48]">
			<div class="flex justify-between items-center p-3 lg:justify-center w-full max-w-4xl lg:max-w-[1200px] z-[48]">
				<router-link to="/">
					<img src="./compImages/logotipo-desde-vino.webp" width="536" loading="lazy" height="91" alt="Logo" class="hidden lg:block w-[536px] h-[91px]"/>
					<img src="./compImages/escudo.webp" loading="lazy" width="55" height="68" alt="Logo" class="lg:hidden "/>
				</router-link>
				<div class="lg:hidden z-[48] bg-white">
					<button @click="toggleMenu" class="bg-white focus:outline-none ">
						<img v-if="!isMenuOpen" src="./compImages/menu_icon.png" loading="lazy" width="41.28" height="32" alt="Abrir menú" class="h-8 bg-white"/>
						<img v-else src="./compImages/menu_close_icon.png" loading="lazy" alt="cerrar menú" class="h-8 bg-white"/>
					</button>
				</div>
			</div>
			<div id="menu" :class="{ 'block': isMenuOpen, 'hidden': !isMenuOpen }" class="lg:flex lg:items-center lg:space-x-4 w-full max-w-4xl lg:max-w-[1200px] z-[48] border border-t-hgold lg:border-none">
				<ul class="flex flex-col lg:flex-row lg:justify-between lg:text-xs w-full divide-y divide-hgold/90 lg:divide-y-0">
					<!-- HUMANITAS -->
					<li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt content-center">
						<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full hover:border-b hover:border-dashed border-gray-400/90 lg:hover:border-b-0">
							<span>HUMANITAS</span>
						</p>
						<ul class="hidden lg:absolute w-full lg:w-[200px] lg:left-0 lg:mt-2 bg-white border border-white lg:space-y-2 lg:group-hover:block lg:bg-opacity-80">
							<router-link to="/nosotros">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									NOSOTROS
								</li>
							</router-link>
							<router-link to="/responsabilidad">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt text-xs" @click="toggleMenu">
									RESPONSABILIDAD
								</li>
							</router-link>
						</ul>
					</li>
					<!-- MODELOS EDUCATIVOS -->
					<li class="lg:hidden min-[1240px]:block lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full hover:border-b hover:border-dashed border-gray-400/90 lg:hover:border-b-0">
							<span>MODELOS EDUCATIVOS</span>
						</p>
						<ul class="hidden lg:absolute w-full lg:w-[200px] lg:left-0 lg:mt-2 bg-white border border-white lg:space-y-2 lg:group-hover:block lg:bg-opacity-80">
							<router-link to="/ejecutivo">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									EJECUTIVO
								</li>
							</router-link>
							<router-link to="/escolarizado">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									ESCOLARIZADO
								</li>
							</router-link>
							<a href="https://virtual.humanitas.edu.mx/" target="_blank">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt text-xs" @click="toggleMenu">
									VIRTUAL
								</li>
							</a>
						</ul>
					</li>
					<!-- LICENCIATURAS -->
					<li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<router-link to="/licenciaturas">
							<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full hover:border-b hover:border-dashed border-gray-400/90 lg:hover:border-b-0">
								<span>LICENCIATURAS</span>
							</p>
						</router-link>
						<ul class="hidden lg:absolute w-full lg:w-[200px] lg:left-0 lg:mt-2 bg-white border border-white lg:space-y-2 lg:group-hover:block lg:bg-opacity-80">
							<router-link to="/licenciatura-en-administracion">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									ADMINISTRACI&Oacute;N
								</li>
							</router-link>
							<router-link to="/licenciatura-en-arquitectura">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									ARQUITECTURA
								</li>
							</router-link>
							<router-link to="/licenciatura-en-arte-y-teatro">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									ARTE Y TEATRO
								</li>
							</router-link>
							<router-link to="/licenciatura-en-ciencias-politicas">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									CIENCIAS POL&Iacute;TICAS Y ADMINISTRACI&Oacute;N P&Uacute;BLICA
								</li>
							</router-link>
							<router-link to="/licenciatura-en-contabilidad">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									CONTABILIDAD
								</li>
							</router-link>
							<router-link to="/licenciatura-en-derecho">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									DERECHO
								</li>
							</router-link>
							<router-link to="/licenciatura-en-diseno-grafico">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									DISE&Ntilde;O GR&Aacute;FICO
								</li>
							</router-link>
							<router-link to="/licenciatura-en-educacion">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									EDUCACI&Oacute;N
								</li>
							</router-link>
							<router-link to="/licenciatura-en-psicologia">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt text-xs" @click="toggleMenu">
									PSICOLOG&Iacute;A
								</li>
							</router-link>
						</ul>
					</li>
					<!-- MAESTRÍAS -->
					<li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<router-link to="/maestrias">
							<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full hover:border-b hover:border-dashed border-gray-400/90 lg:hover:border-b-0">
								<span>MAESTR&Iacute;AS</span>
							</p>
						</router-link>
						<ul class="hidden lg:absolute w-full lg:w-[200px] lg:left-0 lg:mt-2 bg-white border border-white lg:space-y-2 lg:group-hover:block lg:bg-opacity-80">
							<router-link to="/maestria-en-alta-direccion">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									ALTA DIRECCI&Oacute;N CORPORATIVA
								</li>
							</router-link>
							<router-link to="/maestria-en-contabilidad-e-impuestos">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									CONTABILIDAD CON ESPECIALIDAD EN IMPUESTOS
								</li>
							</router-link>
							<router-link to="/maestria-en-derecho-corporativo">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									DERECHO EMPRESARIAL CORPORATIVO
								</li>
							</router-link>
							<router-link to="/maestria-en-derecho-fiscal">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									DERECHO FISCAL
								</li>
							</router-link>
							<router-link to="/maestria-en-derecho-penal">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									DERECHO PENAL
								</li>
							</router-link>
							<router-link to="/maestria-en-educacion">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									EDUCACI&Oacute;N
								</li>
							</router-link>
							<router-link to="/maestria-en-justicia-penal">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									JUSTICIA PENAL Y SEGURIDAD P&Uacute;BLICA
								</li>
							</router-link>
							<router-link to="/maestria-en-mercadotecnia">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									MERCADOTECNIA
								</li>
							</router-link>
							<router-link to="/maestria-en-psicoterapia">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt text-xs" @click="toggleMenu">
									PSICOTERAPIA CON UN ENFOQUE PSICOANAL&Iacute;TICO
								</li>
							</router-link>
						</ul>
					</li>
					<!-- DOCTORADOS -->
					<li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<router-link to="/doctorados">
							<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full hover:border-b hover:border-dashed border-gray-400/90 lg:hover:border-b-0">
								<span>DOCTORADOS</span>
							</p>
						</router-link>
						<ul class="hidden lg:absolute w-full lg:w-[200px] lg:left-0 lg:mt-2 bg-white border border-white lg:space-y-2 lg:group-hover:block lg:bg-opacity-80">
							<router-link to="/doctorado-en-alta-direccion">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									ALTA DIRECCI&Oacute;N
								</li>
							</router-link>
							<router-link to="/doctorado-en-derecho">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									DERECHO
								</li>
							</router-link>
							<router-link to="/doctorado-en-educacion">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt text-xs" @click="toggleMenu">
									EDUCACI&Oacute;N
								</li>
							</router-link>
						</ul>
					</li>
					<!-- EDUCACIÓN CONTINUA -->
					<li class="lg:hidden min-[1240px]:block lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full hover:border-b hover:border-dashed border-gray-400/90 lg:hover:border-b-0">
							<span>EDUCACI&Oacute;N CONTINUA</span>
						</p>
						<ul class="hidden lg:absolute w-full lg:w-[200px] lg:left-0 lg:mt-2 bg-white border border-white lg:space-y-2 lg:group-hover:block lg:bg-opacity-80">
							<router-link to="/educacion-continua">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									DIPLOMADOS &AMP; SEMINARIOS
								</li>
							</router-link>
							<router-link to="/english-kingdom">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									ENGLISH KINGDOM
								</li>
							</router-link>
						</ul>
					</li>
					<!-- <li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<router-link to="/educacion-continua">
							<div class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full" @click="toggleMenu">
								<span>EDUCACI&Oacute;N CONTINUA</span>
							</div>
						</router-link>
					</li> -->
					<!-- CAMPUS -->
					<li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<router-link to="/campus">
							<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full" @click="toggleMenu">
								<span>CAMPUS</span>
							</p>
						</router-link>
					</li>
					<!-- COMUNIDAD -->
					<li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full hover:border-b hover:border-dashed border-gray-400/90 lg:hover:border-b-0">
							<span>COMUNIDAD</span>
						</p>
						<ul class="hidden lg:absolute w-full lg:w-[200px] lg:left-0 lg:mt-2 bg-white border border-white lg:space-y-2 lg:group-hover:block lg:bg-opacity-80">
							<router-link to="/bolsa-de-trabajo">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									BOLSA DE TRABAJO
								</li>
							</router-link>
							<router-link to="/despierta">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs" @click="toggleMenu">
									DESPIERTA
								</li>
							</router-link>
							<a href="https://capitel.humanitas.edu.mx/" target="_blank">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs">
									CAPITEL
								</li>
							</a>
							<a href="https://legatum.humanitas.edu.mx/" target="_blank">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt border-b border-dashed border-gray-400/90 lg:border-b-0 text-xs">
									LEGATUM
								</li>
							</a>
							<a href="https://trascendi.humanitas.edu.mx/" target="_blank">
								<li class="hover:bg-gray-200 menu-mob lg:menu-opt text-xs">
									TRASCENDI
								</li>
							</a>
						</ul>
					</li>
					<!-- BLOG HUMANITAS -->
					<li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<a href="https://humanitas.edu.mx/blog">
							<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full" @click="toggleMenu">
								<span>BLOG HUMANITAS</span>
							</p>
						</a>
					</li>
					<!-- CONTACTO -->
					<li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<router-link to="/contacto">
							<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full" @click="toggleMenu">
								<span>CONTACTO</span>
							</p>
						</router-link>
					</li>
					<!-- USUARIOS -->
					<li class="lg:relative cursor-pointer lg:group hover:bg-gray-200 lg:menu-opt">
						<router-link to="/usuarios">
							<p class="flex items-center justify-center hover:text-hgold py-3 lg:py-0 h-full" @click="toggleMenu">
								<span>USUARIOS</span>
							</p>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<style scoped>
	ul li a {
		text-align: center;
	}

	
</style>